import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Team = () => {
  return (
    <div
      className="uk-section uk-section-muted uk-position-relative uk-overflow-hidden uk-padding-remove-top uk-padding-remove-bottom"
      uk-scrollspy="cls:uk-animation-fade; offset-top: 80px; delay: 300"
    >
      <div className="uk-container uk-position-relative">
        <div
          className="uk-flex uk-flex-center"
          uk-grid="true"
          uk-scrollspy="cls:uk-animation-fade; target: .team-member-item; delay: 300"
        >
          <div className="uk-width-1-1@s uk-width-1-3@m uk-width-1-3@l uk-transition-toggle team-member-item">
            <div className="uk-cover-container uk-transition-toggle uk-margin-bottom uk-position-relative cover-image uk-height-medium">
              <StaticImage
                src="../images/profile/darryl-harris-managing-director.jpg"
                alt="Darryl Harris Managing Director of JWR Ltd"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                title="Darryl Harris Managing Director of JWR Ltd"
                style={{ height: "100%" }}
              />
              <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                <ul className="uk-iconnav">
                 {/*  <li>
                    <a
                      uk-tooltip="title: Let's connect on Facebook; pos: bottom-center"
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on facebook"
                      alt="lets connect on facebook"
                      className="uk-icon-button"
                      uk-icon="facebook"
                    >
                      {null}
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      uk-tooltip="title: Let's connect on Instagram; pos: bottom-center"
                      href="https://www.instagram.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on Instagram"
                      alt="lets connect on instagram"
                      className="uk-icon-button"
                      uk-icon="instagram"
                    >
                      {null}
                    </a>
                  </li> */}
                  <li>
                    <a
                      uk-tooltip="title: Let's connect on LinkedIn; pos: bottom-center"
                      href="https://www.linkedin.com/company/james-walter-raymond"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on LinkedIn"
                      alt="lets connect on LinkedIn"
                      className="uk-icon-button"
                      uk-icon="linkedin"
                    >
                      {null}
                    </a>
                  </li>
                  <li>
                    <a
                      uk-tooltip="title: Email Darryl Harris; pos: bottom-center"
                      href="mailto:darryl.harris@jwr-ltd.co.uk?subject=Facilities and property management services"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on email"
                      alt="lets connect on email"
                      className="uk-icon-button"
                      uk-icon="mail"
                    >
                      {null}
                    </a>
                  </li>
                </ul>
                <div className="uk-margin-medium-top">
                  <Link
                    to={"/article/darryl-harris"}
                    data-uk-icon="arrow-right"
                    className="uk-button uk-button-default uk-button-small button-hover uk-text-capitalize"
                    style={{ borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    <span className="uk-margin-small-right">
                      Read more
                    </span>
                    
                  </Link>
                </div>
              </div>
            </div>

            <span
              className="uk-display-block uk-text-bold"
              style={{ color: "#687169" }}
            >
              Darryl Harris 
            </span>
            <span
              className="uk-display-block"
              style={{ color: "#687169", fontWeight: "500" }}
            >
              Managing Director
            </span>
          </div>

          <div className="uk-width-1-1@s uk-width-1-3@m uk-width-1-3@l uk-transition-toggle team-member-item">
            <div className="uk-cover-container uk-transition-toggle uk-margin-bottom uk-position-relative cover-image uk-height-medium">
              <StaticImage
                src="../images/profile/adam-hawkes-director.jpg"
                alt="Adam Hawkes Director"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                title="Adam Hawkes Director"
                style={{ height: "100%" }}
              />
              <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                <ul className="uk-iconnav">
                  {/* <li>
                    <a
                      uk-tooltip="title: Let's connect on Facebook; pos: bottom-center"
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on facebook"
                      alt="lets connect on facebook"
                      className="uk-icon-button"
                      uk-icon="facebook"
                    >
                      {null}
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      uk-tooltip="title: Let's connect on Instagram; pos: bottom-center"
                      href="https://www.instagram.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on Instagram"
                      alt="lets connect on instagram"
                      className="uk-icon-button"
                      uk-icon="instagram"
                    >
                      {null}
                    </a>
                  </li> */}
                  <li>
                    <a
                      uk-tooltip="title: Let's connect on LinkedIn; pos: bottom-center"
                      href="https://www.linkedin.com/company/james-walter-raymond"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on LinkedIn"
                      alt="lets connect on LinkedIn"
                      className="uk-icon-button"
                      uk-icon="linkedin"
                    >
                      {null}
                    </a>
                  </li>
                  <li>
                    <a
                      uk-tooltip="title: Email Adam Hawkes; pos: bottom-center"
                      href="mailto:adam.hawkes@jwr-ltd.co.uk?subject=Facilities and property management services"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on email"
                      alt="lets connect on email"
                      className="uk-icon-button"
                      uk-icon="mail"
                    >
                      {null}
                    </a>
                  </li>
                </ul>
                <div className="uk-margin-medium-top">
                  <Link
                    to={"/article/adam-hawkes"}
                    data-uk-icon="arrow-right"
                    className="uk-button uk-button-default uk-button-small button-hover uk-text-capitalize"
                    style={{ borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    <span className="uk-margin-small-right">
                      Read more
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <span
              className="uk-display-block uk-text-bold"
              style={{ color: "#687169" }}
            >
              Adam Hawkes
            </span>
            <span
              className="uk-display-block"
              style={{ color: "#687169", fontWeight: "500" }}
            >
              Director
            </span>
          </div>

          <div className="uk-width-1-1@s uk-width-1-3@m uk-width-1-3@l uk-transition-toggle team-member-item">
            <div className="uk-cover-container uk-transition-toggle uk-margin-bottom uk-position-relative cover-image uk-flex uk-flex-center uk-flex-middle">
              <StaticImage
                src="../images/profile/jordan-pullinger-director.jpg"
                alt="Jordan Pullinger Director"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                title="Jordan Pullinger Director"
                style={{ height: "100%" }}
              />

              <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                <ul className="uk-iconnav">
                  {/* <li>
                    <a
                      uk-tooltip="title: Let's connect on Facebook; pos: bottom-center"
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on facebook"
                      alt="lets connect on facebook"
                      className="uk-icon-button"
                      uk-icon="facebook"
                    >
                      {null}
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      uk-tooltip="title: Let's connect on Instagram; pos: bottom-center"
                      href="https://www.instagram.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on Instagram"
                      alt="lets connect on instagram"
                      className="uk-icon-button"
                      uk-icon="instagram"
                    >
                      {null}
                    </a>
                  </li> */}
                  <li>
                    <a
                      uk-tooltip="title: Let's connect on LinkedIn; pos: bottom-center"
                      href="https://www.linkedin.com/company/james-walter-raymond"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on LinkedIn"
                      alt="lets connect on LinkedIn"
                      className="uk-icon-button"
                      uk-icon="linkedin"
                    >
                      {null}
                    </a>
                  </li>
                  <li>
                    <a
                      uk-tooltip="title: Email Jordan Pullinger; pos: bottom-center"
                      href="mailto:jordan.pullinger@jwr-ltd.co.uk?subject=Facilities and property management services"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on email"
                      alt="lets connect on email"
                      className="uk-icon-button"
                      uk-icon="mail"
                    >
                      {null}
                    </a>
                  </li>
                </ul>
                <div className="uk-margin-medium-top">
                  <Link
                    to={"/article/jordan-pullinger"}
                    data-uk-icon="arrow-right"
                    className="uk-button uk-button-default uk-button-small button-hover uk-text-capitalize"
                    style={{ borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    <span className="uk-margin-small-right">
                      Read more
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <span
              className="uk-display-block uk-text-bold"
              style={{ color: "#687169" }}
            >
              Jordan Pullinger
            </span>
            <span
              className="uk-display-block"
              style={{ color: "#687169", fontWeight: "500" }}
            >
              Director
            </span>
          </div>

          {/* <div className="uk-width-1-1@s uk-width-1-2@m uk-width-1-4@l uk-transition-toggle team-member-item">
            <div className="uk-cover-container uk-transition-toggle uk-margin-bottom uk-position-relative cover-image">
              <StaticImage
                src="../images/herman-hero-v2.jpg"
                alt="home-section-one"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                title="home-section-one"
                style={{ height: "100%" }}
              />
              <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                <ul className="uk-iconnav">
                  <li>
                    <a
                      uk-tooltip="title: Let's connect on Facebook; pos: bottom-center"
                      href="https://www.facebook.com/savethestreetpooches"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on facebook"
                      alt="lets connect on facebook"
                      className="uk-icon-button"
                      uk-icon="facebook"
                    >
                      {null}
                    </a>
                  </li>
                  <li>
                    <a
                      uk-tooltip="title: Let's connect on Instagram; pos: bottom-center"
                      href="https://www.instagram.com/savethestreetpoochessrilanka7"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on Instagram"
                      alt="lets connect on instagram"
                      className="uk-icon-button"
                      uk-icon="instagram"
                    >
                      {null}
                    </a>
                  </li>
                  <li>
                    <a
                      uk-tooltip="title: Email Herman; pos: bottom-center"
                      href="mailto:herman@savethestreetpooches.org?subject=Save The Street Pooches Sri Lanka"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="lets connect on email"
                      alt="lets connect on email"
                      className="uk-icon-button"
                      uk-icon="mail"
                    >
                      {null}
                    </a>
                  </li>
                </ul>
                <div className="uk-margin-top	">
                  <Link
                    to={"/article/herman-adu"}
                    className="uk-button uk-button-default uk-button-small uk-border-pill button-hover uk-text-capitalize buttonHover"
                  >
                    Hermans's Story
                  </Link>
                </div>
              </div>
            </div>
            <span
              className="uk-display-block uk-text-bold"
              style={{ color: "#687169" }}
            >
              Herman Adu
            </span>
            <span
              className="uk-display-block"
              style={{ color: "#687169", fontWeight: "500" }}
            >
              Volunteer - IT Director
            </span>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Team
