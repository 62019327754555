import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
import Team from "../components/team"
import SwitcherButtonsContent from "../components/switcher-buttons-content"
import ArticleSlider from "../components/article-slider"
//import Newsletter from "../components/newsletter"
import CtaSlider from "../components/cta-slider"
import TestimonialSliderBackgroundImage from "../components/testimonial-slider-background-image"
import BackgroundImage from "../images/hero/about-us-hero-background.jpg"
import ReactLightbox from "../components/react-lightbox"


const AboutUsPage = pageContext => {
  //console.log("About us - pageContext: ", pageContext)

  const { 
    strapiAboutUs, 
    allStrapiGallerySection 
  } =
    useStaticQuery(graphql`
      query {
        strapiAboutUs {
          hero {
            ...StrapiHero
          }
          seo {
            ...StrapiSeo
            metaSocial {
              socialNetwork
              title
              description
            }
            structuredData {
              _type
              name
              url
              contactPoint {
                _type
                availableLanguage
                contactType
                telephone
              }
              sameAs
            }
          }
          articleSlider {
            articles {
              ...StrapiArticles
            }
          }
          callToAction {
            ...StrapiCallToAction
          }
          slideShowSyncedWithSlider {
            ...SlideShowSyncedWithSliderSections
          }
          featuredSponsorship {
            ...SponsorshipSections
          }
          testimonialSection {
            ...TestimonialCards
          }
          switcherContent {
            ...SwitcherSections
          }
          newsletter {
            headings {
              title
              description
            }
            description
          }
        }
        allStrapiGallerySection(filter: { location: { eq: "about-us-page" } }) {
          nodes {
            ...StrapiGallerySection
          }
        }
      }
    `)

  //console.log("strapiAboutUs: ", strapiAboutUs)

  const {
    hero,
    seo,
    callToAction,
    testimonialSection,
    switcherContent,
    //newsletter,
    articleSlider
  } = strapiAboutUs

  //console.log("strapi about us hero: ", hero)

  const articles = articleSlider.articles
  //console.log("strapiAboutUs - articleSlider articles: ", articles)

  /* const sections =
    strapiAboutUs.slideShowSyncedWithSlider
      .slide_show_synced_with_slider_sections */
  //console.log("slideShowSyncedWithSlider sections: ", sections)

  const callToActionSections = callToAction.call_to_action_sections
  //console.log("strapiAboutUs callToActionSections: ", callToActionSections)

  const testimonialCards = testimonialSection.testimonial_cards
  //console.log("strapiAboutUs - testimonialCards: ", testimonialCards)

  const switcherSection = switcherContent.switcher_sections
  //console.log("strapiAboutUs - switcherSection: ", switcherSection)

  const gallerySection = allStrapiGallerySection.nodes[0]
  //console.log("StrapiGallery: ", gallerySection)

  //let galleryImages = gallerySection.images
  //let remainingImages = galleryImages.splice(6)

  //console.log("galleryImages: ", galleryImages)
  //console.log("remainingImages: ", remainingImages)

  //const imageUrl = `${seo.metaImage.localFile.url}`

  //console.log("strapi about us hero: ", hero)

  
  //console.log("seo: ", seo)

  const structuredData = seo.structuredData;
  //console.log("structuredData: ", structuredData)


  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ContactUsPage",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />  

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
          <Headings
            title="JWR Setting Standards"
            description="We are unwavering in our pursuit of excellence, dedicated to delivering solutions that surpass industry standards and client expectations. Trust is the foundation of our relationships. We conduct business with transparency, honesty, and the highest ethical standards."
          />
          <div style={{ marginTop: "20px"}}>
            <SwitcherButtonsContent switcherSections={switcherSection} />
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
          <Headings
            title="Leadership Team"
            description="Welcome to JWR, where innovative leadership meets a wealth of experience in the dynamic realm of technical operations."
            className="uk-margin-medium-top"
          />
          <div className="uk-container" style={{ marginTop: "20px"}}>
            <Team />
          </div>
        </div>
      </section>

      <TestimonialSliderBackgroundImage
        BackgroundImage={BackgroundImage}
        testimonialCards={testimonialCards}
      />

     {/*  <div className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-top uk-margin-medium-bottom">
          <Newsletter newsletter={newsletter} />
        </div>
      </div> */}

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
         <Headings
            title={gallerySection.headings.title}
            description={gallerySection.headings.description}
          />
          <div className="uk-container" style={{ marginTop: "20px"}}>
            <ReactLightbox
              images={gallerySection.images}
              location={gallerySection.location}
            />
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
          <Headings
            title="Featured Stories"
            description="Checkout some of our stories from Save the Street Pooches"
          />
          <div className="uk-container">
            <ArticleSlider articles={articles} />
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
            <Headings
              title={callToAction.title}
              description={callToAction.description}
            />
          <div className="uk-container uk-margin">
            <CtaSlider callToActionSections={callToActionSections} />
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutUsPage
